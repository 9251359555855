.h-60px {
  height: 50px !important;
}

.w-60px {
  width: 50px !important;
}

.loaderdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}